<template>
  <base-layout>
    <RentStationAreasIndex :hidePlusButton="$acl.canNotCreate('Rent Areas')" />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import RentStationAreasIndex from '@/views/geofence/rent-station-area/index.vue'

export default {
  name: 'RentStationIndex',
  components: {
    BaseLayout,
    RentStationAreasIndex,
  },
}
</script>
